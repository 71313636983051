import React from "react";
import logout from "../../utils/logout";
import { useSelector } from "react-redux";
const Unauthorized = () => {
   const config = useSelector((state) => state.config.config);
  return (
    <div className="d-flex justify-content-center align-items-center vh-75">
      <div className="m-5 shadow-lg p-4 mb-5 bg-body rounded text-center">
        <h3 className="mb-3">Unauthorized Access</h3>
        <p>Your account is set as inactive. Please contact your Organization administrator for any assitance.</p>
        <div>
          <button type="button"
              className="btn-pill btn-shadow btn-shine btn btn-focus btn btn-secondary"
              onClick={() => logout(config)}>
              click here
              </button>
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
