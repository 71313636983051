import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import Loader from "react-loaders";

import { setSideBarItems } from "../reducers/ThemeOptions";
import { loadPartyInfo } from "../actions/party/action";
import EqaroHandler from "./EqaroHandler";
import BasicSetupHandler from "./BasicSetupHandler";
import allPaths from "./allPaths";
import { getToken, addRedirectURL } from "../utils/getToken";
import { getMyprofile } from "../actions/myprofile/action";
import { Mixpanel } from "../utils/mixpanel";
import redirectLoginSSO from "../utils/redirectLoginSSO";
import Unauthorized from "../pages/Unauthorized"; // Import Unauthorized page

const LoadingComponent = () => (
  <div className="loader-container">
    <div className="loader-container-inner">
      <div className="text-center">
        <Loader type="ball-pulse-rise" />
      </div>
      <h6 className="mt-5">
        Crediwatch Risk intelligence decisioning system
        <small>Please wait while Crediwatch prepares a great experience for you</small>
      </h6>
    </div>
  </div>
);

const ErrorMyProfile = () => {
  useEffect(() => {
    Mixpanel.track("error_occurred", { error_type: "Error On Profile" });
  }, []);

  return <div>Error On Profile</div>;
};

const PrivateRoute = (props) => {
  const token = getToken();
  const history = useHistory();
  const { component: Component, path, profile_status, status, ...rest } = props;
  const config = useSelector((state) => state.config.config);
  const Status = _.get(status, "status", "");

  useEffect(() => {
    const { getMyprofile } = props;
    if (!token) {
      addRedirectURL();
      if (config) redirectLoginSSO(config);
    } else {
      getMyprofile();
    }
  }, [config]);

  useEffect(() => {
    if (Status === "inactive") {
      history.replace(allPaths.UnAuthorized);
    }
  }, [Status, history]);

  return (
    <Route
      path={path}
      {...rest}
      render={() =>
        !token || profile_status === "loading" ? (
          <LoadingComponent />
        ) : profile_status === "done" && config ? (
          Status === "inactive" && path !== allPaths.UnAuthorized ? (
            history.replace(allPaths.UnAuthorized) // Redirect to Unauthorized if inactive
          ) : (
            <>
              {path === allPaths.UnAuthorized ? <Unauthorized /> : <Component />}
              <EqaroHandler path={path} />
              <BasicSetupHandler path={path} />
            </>
          )
        ) : profile_status === "error" ? (
          <ErrorMyProfile />
        ) : (
          <LoadingComponent />
        )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  profile_status: _.get(state, "my_profile.profile_status"),
  status: _.get(state, "my_profile.profileData", {}),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getMyprofile, setSideBarItems, loadPartyInfo }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
